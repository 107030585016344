export default class InvitationRequest {
  public company: string;
  public firstName: string;
  public groupIdentifierList: string[];
  public invitedUserEmailAddress: string;
  public inviteRedirectUrl: string;
  public language: string;
  public lastName: string;
  public groupType: number;
  public groupUrl?: string;
  public telephone?: string;
}
