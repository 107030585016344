import * as React from "react";
import { Provider, Flex, Text, Header } from "@fluentui/react";
import TeamsBaseComponent, { ITeamsBaseComponentProps, ITeamsBaseComponentState } from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";


export interface IInviteGuestTabRemoveState extends ITeamsBaseComponentState {
    value: string;
}
export interface IInviteGuestTabRemoveProps extends ITeamsBaseComponentProps {

}

/**
 * Implementation of Invite Guest remove page
 */
export class InviteGuestTabRemove  extends TeamsBaseComponent<IInviteGuestTabRemoveProps, IInviteGuestTabRemoveState> {

    public componentWillMount() {
        this.updateTheme(this.getQueryVariable("theme"));
        this.setState({
            fontSize: this.pageFontSize()
        });

        if (this.inTeams()) {
            microsoftTeams.initialize();
        } else {
        }
    }

    public render() {
        return (
            <Provider theme={this.state.theme}>
                <Flex fill={true}>
                    <Flex.Item>
                        <div>
                            <Text content="We are sad to see you go.. " />
                        </div>
                    </Flex.Item>
                </Flex>
            </Provider>
        );
    }
}
