import * as React from "react";
import { Provider, Flex, Header, Input, Text } from "@fluentui/react";
import TeamsBaseComponent, { ITeamsBaseComponentProps, ITeamsBaseComponentState } from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";

export interface IInviteGuestTabConfigState extends ITeamsBaseComponentState {
    value: string;
}

export interface IInviteGuestTabConfigProps extends ITeamsBaseComponentProps {

}

/**
 * Implementation of Invite Guest configuration page
 */
export class InviteGuestTabConfig  extends TeamsBaseComponent<IInviteGuestTabConfigProps, IInviteGuestTabConfigState> {

    public componentWillMount() {
        this.updateTheme(this.getQueryVariable("theme"));
        this.setState({
            fontSize: this.pageFontSize()
        });

        if (this.inTeams()) {
            microsoftTeams.initialize();

            microsoftTeams.getContext((context: microsoftTeams.Context) => {
                this.setState({ value: context.entityId });
                this.updateTheme(context.theme);
                this.setValidityState(true);
            });

            microsoftTeams.settings.registerOnSaveHandler((saveEvent: microsoftTeams.settings.SaveEvent) => {
                const host = "https://" + window.location.host;

                microsoftTeams.settings.setSettings({
                    suggestedDisplayName: "Guest Hub",
                    contentUrl: host + "/index.html",
                    removeUrl: host + "/remove.html",
                    entityId: this.state.value
                });

                saveEvent.notifySuccess();
            });
        } else {
        }
    }

    public render() {
        return (
            <Provider theme={this.state.theme}>
                <Flex fill={true}>
                    <Flex.Item>
                        <div>
                            <Text>Nothing to configure yet..</Text>
                        </div>
                    </Flex.Item>
                </Flex>
            </Provider>
        );
    }
}
