import * as React from "react";
import {
  Provider,
  Flex,
  Text,
  Button,
  Header,
  Input,
  Loader,
  Box
} from "@fluentui/react";
import TeamsBaseComponent, {
  ITeamsBaseComponentProps,
  ITeamsBaseComponentState,
} from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";
import InvitationRequest from "../models/InvitationRequest";
import {
  TeamCreateIcon,
  ErrorIcon
} from "@fluentui/react-icons-northstar";
import * as configParams from "../../web.config";

/**
 * State for the inviteGuestTabTab React component
 */
export interface IInviteGuestTabState extends ITeamsBaseComponentState {
  entityId?: string;
  context: microsoftTeams.Context;
  invite: InvitationRequest;
  token: string;
  validate: boolean;
  sending: boolean;
  success: boolean;
  error: boolean;
}

/**
 * Properties for the inviteGuestTabTab React component
 */
export interface IInviteGuestTabProps extends ITeamsBaseComponentProps {}

/**
 * Implementation of the Invite Guest content page
 */
export class InviteGuestTab extends TeamsBaseComponent<
  IInviteGuestTabProps,
  IInviteGuestTabState
> {
  public componentWillMount() {
    this.updateTheme(this.getQueryVariable("theme"));
    this.setState({
      fontSize: this.pageFontSize(),
      validate: false,
      sending: false,
      success: false,
      error: false
    });

    if (this.inTeams()) {
      microsoftTeams.initialize();
      microsoftTeams.registerOnThemeChangeHandler(this.updateTheme);
      microsoftTeams.getContext((context) => {
        this.setState({
          context,
          entityId: context.entityId,
          invite: this.getEmptyInvite(context),
        });

        this.updateTheme(context.theme);
      });

      const resourceUrl = "api://" + window.location.host + "/user_impersonation";

      const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
        resources: [ resourceUrl ],
        successCallback: (result) => {
          this.setState({ token: result });
        },
        failureCallback: (error) => {
          console.log("Failure - Fetching token - " + error);
        },
      };

      microsoftTeams.authentication.getAuthToken(authTokenRequest);

    } else {
      this.setState({ entityId: "This is not hosted in Microsoft Teams, we cannot add context from here." });
    }
  }

  public validateInputs() {
    this.setState({ validate: true });
  }

  public getEmptyInvite(context: microsoftTeams.Context) {
    const newInvite = new InvitationRequest();
    newInvite.groupIdentifierList = [context.teamName ? context.teamName : ""];
    newInvite.inviteRedirectUrl = `https://teams.microsoft.com/_#/conversations/${context.channelName}?threadId=${context.teamId}&ctx=channel`;
    newInvite.language = context.locale;
    newInvite.groupType = 2;
    newInvite.firstName = "";
    newInvite.lastName = "";
    newInvite.invitedUserEmailAddress = "";
    newInvite.company = "";

    return newInvite;
  }

  public handleCancelInvite = () => {
    this.setState({ invite: this.getEmptyInvite(this.state.context) });
    this.setState({ validate: false });
  }

  public sendInvite = async (newInvite: InvitationRequest) => {
    const apiUrl = configParams.baseUriApi + "/api/invitation/InviteUser";
    const headersList = this.getHeaders();

    return fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify(newInvite),
      headers: headersList,
      cache: "no-cache"
    });
  }

  public hasError(validate: boolean, values: string[]) {
    let hasError = false;
    if (validate) {
      hasError = values.includes(undefined || "");
    }

    return hasError;
  }

  public handleSendInvite = () => {
    this.setState({
      sending: true,
      validate: true,
      error: false,
      success: false
     });

    if (!this.hasError(true, [ this.state.invite.firstName, this.state.invite.lastName,
                               this.state.invite.invitedUserEmailAddress, this.state.invite.company ]) &&
        this.state.invite.invitedUserEmailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      let inviteRequest = new InvitationRequest();
      inviteRequest = this.state.invite;

      this.sendInvite(inviteRequest)
        .then((response) => {
          this.handleCancelInvite();
          this.setState({
            success: response.ok,
            error: !response.ok
           });
        })
        .finally(() => {
          this.setState({ sending: false });
        });
    } else {
      this.setState({
        sending: false
       });
    }
  }

  public getHeaders = () => {
    const headers = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Authorization", "Bearer " + this.state.token);

    return headers;
  }

  /**
   * The render() method to create the UI of the tab
   */
  public render() {
    return (
      <Provider theme={this.state.theme}>
        <Flex
          fill={true}
          column
          styles={{
            padding: "0 1.8rem",
          }}>
          <Flex.Item>
            <Header content="Invite Guest user" />
          </Flex.Item>
          <Flex.Item>
            <Text content="With this form you can invite guest users from outside your organisation to this Team. The invitation will be send by mail to your guest via GuestHub." />
          </Flex.Item>
          <Flex.Item styles={{ padding: ".8rem 0" }}>
            <div>
              {this.state.sending && <Loader inline size="small" label="Creating your invite..." labelPosition="end" />}
              {this.state.success && <Text color="green">Successfully created your invite!</Text>}
              {this.state.error && <Text color="red">Error occured during creation, try again or contact your IT department</Text>}
            </div>
          </Flex.Item>
          <Flex.Item>
            <div>
              {this.state.invite && (
                <Flex
                  column
                  hAlign="stretch">
                  <Flex.Item
                    styles={{ padding: ".8rem 0" }}>
                    <div>
                      <Text weight="bold" content="Name  " />
                      <Text content="*" color="red" />
                      <Flex hAlign="stretch" gap="gap.small" styles={{ paddingTop: ".8rem" }}>
                        <Flex.Item size="size.half">
                          <Flex column hAlign="stretch" gap="gap.smaller">
                            <Input
                              placeholder="First name"
                              fluid
                              clearable
                              required
                              value={this.state.invite.firstName}
                              disabled={this.state.sending}
                              onChange={(e, data) => {
                                if (data) {
                                  this.setState({
                                    invite: {
                                      ...this.state.invite,
                                      firstName: data.value,
                                    },
                                  });
                                }
                              }} />

                            {this.state.validate && !this.state.invite.firstName && (
                              <Text
                                size="small"
                                color="red"
                                content="  First name is required"
                                styles={{ marginRight: ".8rem" }} />
                            )}
                          </Flex>
                        </Flex.Item>
                        <Flex.Item size="size.half">
                          <Flex column gap="gap.smaller">
                            <Input
                              placeholder="Last name"
                              fluid
                              clearable
                              required
                              value={this.state.invite.lastName}
                              disabled={this.state.sending}
                              onChange={(e, data) => {
                                if (data) {
                                  this.setState({
                                    invite: {
                                      ...this.state.invite,
                                      lastName: data.value,
                                    },
                                  });
                                }
                              }} />

                            {this.state.validate && !this.state.invite.lastName && (
                              <Text
                                size="small"
                                color="red"
                                content="  Last name is required" />
                            )}
                          </Flex>
                        </Flex.Item>
                      </Flex>
                    </div>
                  </Flex.Item>
                  <Flex.Item
                    styles={{
                      padding: ".8rem 0 .8rem",
                    }}>
                    <div>
                      <Text weight="bold" content="Details  " />
                      <Text content="*" color="red" />
                      <Input
                        styles={{
                          marginTop: ".8rem",
                        }}
                        placeholder="E-mail address"
                        fluid
                        type="email"
                        disabled={this.state.sending}
                        clearable
                        required
                        value={this.state.invite.invitedUserEmailAddress}
                        onChange={(e, data) => {
                          if (data) {
                            this.setState({
                              invite: {
                                ...this.state.invite,
                                invitedUserEmailAddress: data.value,
                              },
                            });
                          }
                        }} />
                      {this.state.validate && !this.state.invite.invitedUserEmailAddress && (
                        <Text
                          size="small"
                          color="red"
                          content="  E-mail address is required"
                          styles={{
                            marginRight: ".8rem",
                          }} />
                      )}
                      {this.state.validate && this.state.invite.invitedUserEmailAddress &&
                        !this.state.invite.invitedUserEmailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && (
                        <Text
                          size="small"
                          color="red"
                          content="  E-mail address is not valid"
                          styles={{
                            marginRight: ".8rem",
                          }} />
                      )}
                    </div>
                  </Flex.Item>
                  <Flex.Item
                    styles={{ padding: ".4rem 0 .8rem" }}>
                    <div>
                      <Input
                        placeholder="Company name"
                        fluid
                        clearable
                        required
                        disabled={this.state.sending}
                        value={this.state.invite.company}
                        onChange={(e, data) => {
                          if (data) {
                            this.setState({
                              invite: {
                                ...this.state.invite,
                                company: data.value,
                              },
                            });
                          }
                        }} />
                      {this.state.validate && !this.state.invite.company && (
                        <Text
                          size="small"
                          color="red"
                          content="  Company name is required" />
                      )}
                    </div>
                  </Flex.Item>
                </Flex>
              )}
            </div>
          </Flex.Item>
          <Flex.Item>
            <Flex gap="gap.small" hAlign="end">
              <Button
                icon={<TeamCreateIcon />}
                onClick={() => this.handleSendInvite()}
                primary
                disabled={this.state.sending}>
                    {this.state.sending ? "Creating..." : "Create invite"}
              </Button>
              <Button
                onClick={() => this.handleCancelInvite()}
                content="Clear"
                secondary
                disabled={this.state.sending} />
            </Flex>
          </Flex.Item>
          <Flex.Item
            styles={{
              padding: ".8rem 0 .8rem .5rem",
            }}>
            <Text size="smaller" content="(C) Copyright Portiva" />
          </Flex.Item>
        </Flex>
      </Provider>
    );
  }
}
